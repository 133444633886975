'use strict';

angular.module('lmsApp')
    .factory('UserGroup', function ($resource) {
        return $resource('api/userGroups/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'getAllRich': {
                method: 'GET',
                url: 'api/userGroups/rich',
                isArray: true
            },
            'get': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'create': { method:'POST' },
            'update': { method:'PUT' },
            'getAllByTutorId': {
                method: 'GET',
                url: 'api/userGroups/findByTutorId/:tutorId',
                isArray: true
            },
            'getFreeUserGroups': {
                method: 'GET',
                url: 'api/userGroups/free/:currentUserGroupId',
                isArray: true
            },
            'getAllGroups': {
                method: 'GET',
                url: 'api/userGroups',
                isArray: true
            },
            'checkLearningClasses': {
                method: 'GET',
                url: 'api/userGroups/checkLearningClasses',
                isArray: true
            }
        });
    });
