'use strict';

angular.module('lmsApp').controller('orgStructureController', [
  '$scope',
  '$state',
  '$window',
  '$q',
  'Locations',
  'Region',
  'SchoolNew',
  'Territory',
  'School',
  'Learningclass',
  'User',
  'Users',
  'UserGroup',
  '$rootScope',
  function(
    $scope,
    $state,
    $window,
    $q,
    Locations,
    Region,
    SchoolNew,
    Territory,
    School,
    Learningclass,
    User,
    Users,
    UserGroup,
    $rootScope
  ) {
    $scope.regions = [];
    $scope.regionsLongList = null;
    $scope.region = null;
    $scope.territoriesLongList = null;
    $scope.territory = null;
    $scope.schoolsLongList = null;
    $scope.school = null;
    $scope.learningclass = {};
    $scope.years = []; // годы обучения в фильтре на общей странице и на странице "Просмотр" (объекты)
    $scope.yearValues = []; // годы обучения в модальном окне создания/редактирования учебного класса (только числовые значения)
    $scope.selectedYear = {}; // выбранный год, по дефолту - текущий учебный год
    $scope.userGroup = null;
    $scope.expandedBranches = [];
    $scope.nameUserEdit = false;
    $scope.treeOptions = {
      dirSelectable: false,
      isLeaf: function(node) {
        if (node.hasOwnProperty('users')) {
          return true;
        }
        return false;
      },
      injectClasses: {
        iExpanded: 'indicator glyphicon glyphicon-folder-open',
        iCollapsed: 'indicator glyphicon glyphicon-folder-close'
      }
    };
    $scope.longListOptions = {
      page: 1,
      per_page: 10,
      sorting_fields: ['id'],
      sorting_direction: 'asc'
    };
    function typeChercker(node) {
      if (node.hasOwnProperty('type')) {
        if (node.type === 'REGION') {
          return 'REGION';
        } else if (node.type === 'TERRITORY') {
          return 'TERRITORY';
        } else {
          console.log('incorrect type of orgstructuure');
        }
      }
    }
    $scope.loadBranch = function(node, expanded, forceReload, fn) {
      _.assign(node, { expanded: expanded });
      var isReload = forceReload || !node.children, callbackFn = fn || null;
      if (!expanded) return;
      switch (node.type) {
        case 'TERRITORY':
          isReload &&
            School.getByTerritory(
              {
                id: node.id
              },
              function(result) {
                !!result.length &&
                  result.forEach(function(item) {
                    _.assign(item, {
                      type: 'SHCOOL',
                      location: {
                        id: node.id,
                        name: node.name
                      }
                    });
                  });
                node.children = result;
                callbackFn && callbackFn();
              }
            );
          break;
        case 'SHCOOL':
          isReload &&
            Learningclass.getBySchool(
              {
                id: node.id
              },
              function(result) {
                !!result.length &&
                  result.forEach(function(item) {
                    _.assign(item, {
                      type: 'LEARNING_CLASS'
                    });
                  });
                node.children = result.map(function(res) {
                  var learningClass = {
                    id: res.id,
                    letter: res.letter,
                    name: res.name,
                    parallel: res.parallel,
                    school: res.school
                  };
                  if (res.userGroup) {
                    res.userGroup.learningClasses = [];
                    res.userGroup.learningClasses.push(learningClass);
                  }
                  return res;
                });
              }
            );
          break;
        case 'LEARNING_CLASS':
          node.children = [];
          if (node.userGroup) {
            _.assign(node.userGroup, { type: 'USER_GROUP' });
            node.children.push(node.userGroup);
          }
          $scope.userGroupCount = node.children.length;
          break;
        default:
          isReload &&
            Locations.children(
              {
                id: node.id
              },
              function(result) {
                !!result.length &&
                  result.forEach(function(item) {
                    _.assign(item, {
                      type: 'TERRITORY',
                      region: {
                        id: node.id,
                        name: node.name
                      }
                    });
                  });
                node.children = result;
                callbackFn && callbackFn();
              }
            );
      }
    };
    $scope.update = function(node) {
      switch (node.type) {
        case 'REGION':
          $scope.region = node;
          $('#saveRegionModal').modal('show');
          break;
        case 'TERRITORY':
          $scope.territory = node;
          $scope.getRegionsForLongList();
          $('#saveTerritoryModal').modal('show');
          break;
        case 'SHCOOL':
          $scope.school = node;
          $scope.getTerritoriesForLongList();
          $('#saveSchoolModal').modal('show');
          break;
        case 'LEARNING_CLASS':
          $scope.learningclass = node;
          $scope.getSchoolsForLongList();
          $scope.loadFreeUserGroupsAndCurrentUserGroup();
          $('#saveLearningclassModal').modal('show');
          break;
        case 'USER_GROUP':
          $scope.userGroup = node;
          $scope.getTutors();
          $('#saveUserGroupModal').modal('show');
          break;
      }
    };
    $scope.delete = function(node) {
      switch (node.type) {
        case 'REGION':
          $scope.region = node;
          $('#deleteRegionConfirmation').modal('show');
          break;
        case 'TERRITORY':
          $scope.territory = node;
          $('#deleteTerritoryConfirmation').modal('show');
          break;
        case 'SHCOOL':
          $scope.school = node;
          $('#deleteSchoolConfirmation').modal('show');
          break;
        case 'LEARNING_CLASS':
          $scope.learningclass = node;
          $('#deleteLearningclassConfirmation').modal('show');
          break;
      }
    };
    $scope.openDetails = function(node) {
      var equality = {
        REGION: 'regionDetail',
        TERRITORY: 'territoryDetail',
        SHCOOL: 'schoolDetail',
        LEARNING_CLASS: 'learningclassDetail',
        USER_GROUP: 'userGroupDetail'
      },
        winContent = equality[node.type];
      winContent && $window.open($state.href(winContent, { id: node.id }), '_blank');
    };
    $scope.loadAll = function() {
      Locations.query(
        {
          type: 'REGION'
        },
        function(result) {
          !!result.length &&
            result.forEach(function(item) {
              _.assign(item, {
                type: 'REGION'
              });
            });
          $scope.regions = result;
        }
      );
    };
    $scope.loadAll();
    $scope.openPopup = function($event, name, node) {
      switch (name) {
        case 'saveTerritoryModal':
          $scope.clearTerritory(node);
          $scope.getRegionsForLongList();
          break;
        case 'saveSchoolModal':
          $scope.clearSchool(node);
          $scope.getTerritoriesForLongList();
          break;
        case 'saveLearningclassModal':
          $scope.clearLearningClass(node);
          $scope.getSchoolsForLongList();
            // Получить с бэкенда список годов
            Learningclass.getYears().$promise.then(function(response) {
                $scope.years = response;
                $scope.yearValues = response.filter(function(year) {
                return year.value >= getСurrentYear();
                }).map(function(year) {
                return year.value;
                });
                return $scope.years.filter(function(year) {
                return year.value === getСurrentYear();
                });
            }).then(function(selectedYear) {
                // Выбрать по дефолту текущий учебный год и загрузить первичную информацию на страницу
                $scope.selectedYear = selectedYear[0].value;
                $scope.learningclass.year = getСurrentYear();
            });

            // Показать по дефолту текущий учебный год
            function getСurrentYear() {
                var date = new Date(),
                month = date.getMonth(),
                year = date.getFullYear();
                if (month >= 5) {
                //текущий учебный год - начиная с июня, так как вряд ли в июне будут создаваться учебные классы за подходящий к концу год
                return $scope.currentYear = year;
                } else {
                return $scope.currentYear = year - 1;
                }
            }
          break;
        case 'saveUserGroupModal':
          $scope.learningclass = node;
          if (!node.userGroup || node.userGroup.learningClasses === undefined) {
            node.userGroup = {};
            node.userGroup.learningClasses = [];
            var learningClass = {
              id: node.id,
              name: node.name,
              letter: node.letter,
              parallel: node.parallel,
              school: node.school
            };
            node.userGroup.learningClasses.push(learningClass);
          }
          $scope.clearUserGroup($rootScope.user, node.name, node.userGroup.learningClasses);
          $scope.getTutors();
          break;
      }
      $('#' + name).modal('show');
      $event.stopPropagation();
    };
    //Region related methods
    $scope.saveRegion = function() {
      var locationId = $scope.region.id,
        region = {
          name: $scope.region.name,
          type: 'REGION'
        },
        locationFn = !!locationId ? 'updateLocation' : 'addLocation';
      !!locationId &&
        _.assign(region, {
          id: locationId
        });
      $scope[locationFn](region, function() {
        $scope.loadAll();
        $('#saveRegionModal').modal('hide');
        $scope.clearRegion();
      });
    };
    $scope.confirmDeleteRegion = function(id) {
      Region.delete(
        {
          id: id
        },
        function() {
          $scope.regions = _.reject($scope.regions, {
            id: id
          });
          $('#deleteRegionConfirmation').modal('hide');
        }
      );
    };
    $scope.clearRegion = function() {
      $scope.region = {
        name: null,
        id: null
      };
      $scope.editRegionForm.$setPristine();
      $scope.editRegionForm.$setUntouched();
    };
    $scope.getRegionsForLongList = function(params) {
      var loadOptions = _.assign($scope.longListOptions, params, { type: 'REGION' });
      Locations.query(loadOptions, function(result, headers) {
        $scope.regionsLongList = {
          items: result,
          total: headers('X-Total-Count')
        };
      });
    };
    //Location related methods
    $scope.addLocation = function(location, fn) {
      Locations.addLocation(location, fn);
    };
    $scope.updateLocation = function(location, fn) {
      Locations.editLocation(location, fn);
    }; //Territory related methods
    $scope.saveTerritory = function() {
      var locationId = $scope.territory.id,
        territory = {
          name: $scope.territory.name,
          type: 'TERRITORY',
          parentId: $scope.territory.region.id
        },
        locationFn = !!locationId ? 'updateLocation' : 'addLocation';
      !!locationId && _.assign(territory, { id: locationId });
      $scope[locationFn](territory, function() {
        $scope.loadBranch($scope.territory.region, true, true, function() {
          $('#saveTerritoryModal').modal('hide');
          $scope.clearTerritory();
        });
      });
    };
    $scope.confirmDeleteTerritory = function(id) {
      Territory.delete(
        {
          id: id
        },
        function() {
          removeNode(id);
          $('#deleteTerritoryConfirmation').modal('hide');
        }
      );
    };
    $scope.clearTerritory = function(region) {
      $scope.territory = {
        name: null,
        region: region,
        id: null
      };
      $scope.editTerritoryForm.$setPristine();
      $scope.editTerritoryForm.$setUntouched();
    };
    $scope.getTerritoriesForLongList = function(params) {
      var loadOptions = _.assign($scope.longListOptions, params, { type: 'TERRITORY' });
      Locations.query(loadOptions, function(result, headers) {
        $scope.territoriesLongList = {
          items: result,
          total: headers('X-Total-Count')
        };
      });
    };
    //School related methods
    $scope.createSchool = function() {
      var school = {
        name: $scope.school.name,
        locationId: $scope.school.location.id,
        id: $scope.school.id ? $scope.school.id : null
      };
      if (school.id === null) {
        SchoolNew.create(school, function() {
          $scope.loadBranch($scope.school.location, true, true, function() {
            $('#saveSchoolModal').modal('hide');
            $scope.clearSchool();
          });
        });
      } else {
        SchoolNew.update(school, function() {
          $scope.loadAll();
          $('#saveSchoolModal').modal('hide');
          $scope.clearSchool();
        });
      }
    };
    $scope.confirmDeleteSchool = function(id) {
      School.delete(
        {
          id: id
        },
        function() {
          removeNode(id);
          $('#deleteSchoolConfirmation').modal('hide');
        }
      );
    };
    $scope.clearSchool = function(territory) {
      $scope.school = {
        name: null,
        id: null,
        location: territory
      };
      $scope.editSchoolForm.$setPristine();
      $scope.editSchoolForm.$setUntouched();
    };
    $scope.getSchoolsForLongList = function(loadOptions) {
      loadOptions = loadOptions ? loadOptions : $scope.longListOptions;
      School.query(loadOptions, function(result, headers) {
        $scope.schoolsLongList = {
          items: result,
          total: headers('X-Total-Count')
        };
      });
    }; //LearningClass related methods




    $scope.createLearningClass = function() {
      Learningclass.update($scope.learningclass, function(result) {
        $scope.learningclass.school.children.push(_.assign(result, { type: 'LEARNING_CLASS' }));
        $('#saveLearningclassModal').modal('hide');
        $scope.clearLearningClass();
      });
    };
    $scope.confirmDeleteLearningClass = function(id) {
      Learningclass.delete({ id: id }, function() {
        removeNode(id);
        $('#deleteLearningclassConfirmation').modal('hide');
      });
    };
    $scope.clearLearningClass = function(school) {
      $scope.learningclass = {
        name: null,
        parallel: null,
        letter: null,
        id: null,
        school: school
      };
      $scope.editLearningClassForm.$setPristine();
      $scope.editLearningClassForm.$setUntouched();
    };
    $scope.getOwners = function(loadOptions) {
      loadOptions = loadOptions ? loadOptions : $scope.longListOptions;
      User.getPossibleOwners(loadOptions, function(result, headers) {
        $scope.owners = {
          items: result,
          total: headers('X-Total-Count')
        };
      });
    };
    $scope.getTutors = function(loadOptions) {
      loadOptions = loadOptions ? loadOptions : $scope.longListOptions;
      loadOptions.learningclass = $scope.learningclass !== undefined &&
        $scope.learningclass !== null
        ? $scope.learningclass.id
        : $scope.userGroup.learningClasses[0].id;
      Users.getTutors(loadOptions, function(result, headers) {
        $scope.tutors = {
          items: result,
          total: headers('X-Total-Count')
        };
      });
    };
    function removeNode(id) {
      $scope.expandedBranches.forEach(function(item) {
        if (item.children.length) {
          item.children = _.reject(item.children, { id: id });
        }
      });
    }
    $scope.clearUserGroup = function(owner, name, learningClasses) {
      $scope.userGroup = {
        name: name ? name : null,
        owner: owner ? owner : null,
        id: null
      };
      $scope.userGroup.learningClasses = learningClasses;
      $scope.editUserGroupForm.$setPristine();
      $scope.editUserGroupForm.$setUntouched();
    };
    //learningclass
    $scope.createUserGroup = function() {
      if (
        ($scope.userGroup && $scope.userGroup.tutor && $scope.learningclass) ||
        $scope.userGroup.learningClasses
      ) {
        var usergroup = {
          tutorId: $scope.userGroup.tutor ? $scope.userGroup.tutor.id : null,
          learningClassId: $scope.learningclass
            ? $scope.learningclass.id
            : $scope.userGroup.learningClasses[0].id,
          name: $scope.userGroup.name
        };
        if ($scope.userGroup.id) {
          usergroup.id = $scope.userGroup.id;
          if ($scope.userGroup.users !== undefined && $scope.userGroup.users.length > 0) {
            usergroup.userIds = $scope.userGroup.users.map(function(user) {
              return user.id;
            });
          }
        }
      }
      UserGroup.update(
        usergroup,
        function(result) {
          $scope.userGroup = result;
          $scope.learningclass.userGroup = result;
          $scope.loadBranch($scope.learningclass, true);
          $('#saveUserGroupModal').modal('hide');
        },
        function(error) {
          new Error(error, 'fail to save userGroup');
        }
      );
    };
    $scope.loadFreeUserGroupsAndCurrentUserGroup = function(loadOptions, currentUserGroupId) {
      loadOptions = loadOptions ? loadOptions : $scope.longListOptions;
      loadOptions.currentUserGroupId = -1;
      UserGroup.getFreeUserGroups(loadOptions, function(result, headers) {
        $scope.userGroups = {
          items: result,
          total: headers('X-Total-Count')
        };
      });
    };
    $scope.changeName = function(e) {
      if ($scope.learningclass && $scope.learningclass.name && $scope.learningclass.name !== '') {
        $scope.nameUserEdit = true;
      } else {
        $scope.nameUserEdit = false;
      }
    };
    $scope.changeLetterParallel = function(e) {
      if ($scope.learningclass && !$scope.nameUserEdit) {
        $scope.learningclass.name = '';
        $scope.learningclass.name += $scope.learningclass.parallel
          ? $scope.learningclass.parallel
          : '';
        $scope.learningclass.name += $scope.learningclass.letter ? $scope.learningclass.letter : '';
      }
    };
  }
]);
