(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "dev userImport2",
	"commit": "bf58fed2e2bfe93946c3aed79c527f8e80ed086d",
	"branch": "dev",
	"date": "2020-03-16T08:38:37.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
;
})();
