'use strict';
angular.module('lmsApp').controller('UserImport2Controller', [
    '$scope',
    'ImportUsers',
    'Locations',
    'Learningclass',
    'School',
    '$timeout',
    'usSpinnerService',
    function ($scope, ImportUsers, Locations, Learningclass, School, $timeout, usSpinnerService) {

        var TAB = '\t';
        var ENTER = '\n';

        // init
        $scope.spinnerUserImport2 = false;
        // /init

        // showStep1
        $scope.showStep1 = true;
        $scope.step1Data = {
            // select
            regions: [],
            territories: [],
            schools: [],
            years: [],
            // importSelects
            selectedRegion: null,
            selectedTerritory: null,
            selectedSchool: null,
            selectedYear: null,
            // -----------
            importData: '',
            parse: step1Parse,
            startNew: reset,
            onRegionChange: onRegionChange,
            onTerritoryChange: onTerritoryChange,
        };
        // /showStep1
        // showStep2
        $scope.showStep2 = false;
        $scope.step2Data = {
            dataReadyStr: '',
            dataReadyTable: [],
            // -----
            checkData: step2CheckData,
            back: step2Back,
            startNew: reset
        };
        // /showStep2
        // showStep3
        $scope.showStep3 = false;
        $scope.step3Data = {
            isValid: false,
            data: [],
            // -----
            isDataObject: isDataObject,
            isDataArray: isDataArray,
            getClassName: getClassName,
            // -----
            startImportUsers: step3sStartImportUsers,
            back: step3Back,
            startNew: reset
        };
        // /showStep2
        // showStep4
        $scope.showStep4 = false;
        $scope.step4Data = {
            importedUsers: [],
            startNew: reset
        };
        // /showStep4

        init();

        function reset() {
            setStep(1);
            $scope.step1Data.selectedRegion = null;
            $scope.step1Data.selectedTerritory = null;
            $scope.step1Data.selectedSchool = null;
            $scope.step1Data.selectedYear = null;
            $scope.step1Data.importData = '';
            // ----
            $scope.step2Data.dataReadyStr = '';
            $scope.step2Data.dataReadyTable = [];
            // ----
            $scope.step3Data.isValid = false;
            $scope.step3Data.data = [];
            // ----
            $scope.step4Data.importedUsers = [];
        }

        function setStep(num) {
            $scope.showStep1 = false;
            $scope.showStep2 = false;
            $scope.showStep3 = false;
            $scope.showStep4 = false;
            if (num === 1) {
                $scope.showStep1 = true;
            } else if (num === 2) {
                $scope.showStep2 = true;
            } else if (num === 3) {
                $scope.showStep3 = true;
            } else if (num === 4) {
                $scope.showStep4 = true;
            }
        }

        function spinnerShow() {
            $scope.spinnerUserImport2 = true;
            $timeout(function () {
                usSpinnerService.spin('spinnerUserImport2');
            }, 0);
        }

        function spinnerHide() {
            $scope.spinnerUserImport2 = false;
            usSpinnerService.stop('spinnerUserImport2');
        }

        function init() {
            Locations.query({
                type: 'REGION'
            }, function (regions) {
                $scope.step1Data.regions = regions;
                Learningclass.getYears(function (years) {
                    $scope.step1Data.years = years;
                });
            });
        };

        function onRegionChange() {
            if (!!$scope.step1Data.selectedRegion) {
                Locations.children({ id: $scope.step1Data.selectedRegion.id }, function (result) {
                    $scope.step1Data.territories = result;
                });
            }
            $scope.step1Data.selectedTerritory = null;
            $scope.step1Data.selectedSchool = null;
        }

        function onTerritoryChange() {
            if (!!$scope.step1Data.selectedTerritory) {
                $scope.step1Data.schools = School.getByTerritory({
                    id: $scope.step1Data.selectedTerritory.id
                });
            }
            $scope.step1Data.selectedSchool = null;
        }

        function step1Parse() {
            try {
                var importSelects = {
                    selectedRegion: $scope.step1Data.selectedRegion,
                    selectedTerritory: $scope.step1Data.selectedTerritory,
                    selectedSchool: $scope.step1Data.selectedSchool,
                    selectedYear: $scope.step1Data.selectedYear
                };
                var importData = $scope.step1Data.importData;
                // ------
                var dataReadyStr = parseData(importSelects, importData);
                var dataReadyTable = getDataReadyTable(dataReadyStr);
                $scope.step2Data.dataReadyStr = dataReadyStr;
                $scope.step2Data.dataReadyTable = dataReadyTable;
                // console.log($scope.dataReadyTable);
                $scope.showStep1 = false;
                $scope.showStep2 = true;
            } catch (error) {
                alert(error.message);
            }
        };

        function parseData(importSelects, importData) {
            try {
                var selects = validateSelects(importSelects);
                // console.log('importSelects', importSelects);
                var arr = validateData(importData);
                // console.log('arr', arr);
                // Содединяем всё в строку
                var str = runGlue(selects, arr);
                // console.log('str', str);
                return str;
            } catch (error) {
                throw error;
            }
        };

        function validateSelects(importSelects) {
            // console.log('importSelects', importSelects)
            if (!(
                importSelects.selectedRegion &&
                importSelects.selectedTerritory &&
                importSelects.selectedSchool &&
                importSelects.selectedYear
            )) {
                throw new Error('Не выбраны данные в выпадающих списках!');
            }
            // ----
            var data = {
                region: importSelects.selectedRegion.name,
                territory: importSelects.selectedTerritory.name,
                school: importSelects.selectedSchool.name,
                year: importSelects.selectedYear.name,
            };
            return data;
        };

        function validateData(data) {
            if (data === '') {
                throw new Error('Заполните данные!');
            }
            // -------
            var rowsSplit = data.split(ENTER);
            var rows = [];
            for (var index = 0; index < rowsSplit.length; index++) {
                var row = rowsSplit[index];
                if (!!row) {
                    rows.push(row);
                }
            }
            // console.log('rows', rows);
            var error = false;
            var checkLength = null;
            for (var index = 0; index < rows.length; index++) {
                var row = rows[index];
                var cols = row.split(TAB);
                for (var trimIndex = 0; trimIndex < cols.length; trimIndex++) {
                    var element = cols[trimIndex];
                    var trim = element.trim();
                    if (trim === '') {
                        error = true;
                    }
                }
                var colsLength = cols.length;
                if (index === 0) {
                    checkLength = colsLength;
                } else {
                    if (colsLength !== checkLength) {
                        error = true;
                        break;
                    }
                }
            }
            // console.log('error', error);
            if (error) {
                throw new Error('В данных ошибка!, В какой-то строчке не хватает столбца либо пустой столбец!');
            }
            // ----
            var arr = [];
            for (var index = 0; index < rows.length; index++) {
                var row = rows[index];
                var cols = row.split(TAB);
                arr.push(cols);
            }
            if (arr[0] && arr[0].length !== 8) {
                throw new Error('Cтолбцов, должно быть 8!');
            }
            // ----
            return arr;
        };

        function runGlue(selects, arr) {
            var str = '';
            // ----
            var selectsStr = selects.region + TAB + selects.territory + TAB + selects.school + TAB + selects.year;
            // ----
            for (var index = 0; index < arr.length; index++) {
                var row = arr[index];
                var rowStr = row.join(TAB);
                str = str + selectsStr + TAB + rowStr + ENTER;
            }
            return str;
        };

        function step2Back() {
            setStep(1);
        }

        function step2CheckData() {
            var dataReadyStr = $scope.step2Data.dataReadyStr;
            // Отсылаем данные
            spinnerShow();
            ImportUsers.checkData({ importData: dataReadyStr }, function (response) {
                spinnerHide();
                // console.log('response', response);
                $scope.step3Data.isValid = response.isValid;
                $scope.step3Data.data = response.data;
                setStep(3);
            }, function (error) {
                spinnerHide();
                console.log('error', error);
                alert('Что то пошло не так!');
            });
        }

        function step3Back() {
            setStep(1);
        }

        function step3sStartImportUsers() {
            var dataReadyStr = $scope.step2Data.dataReadyStr;
            // Отсылаем данные
            spinnerShow();
            ImportUsers.post({ importData: dataReadyStr }, function (response) {
                spinnerHide();
                $scope.step4Data.importedUsers = response;
                setStep(4);
                alert('Удачно!');
            }, function (error) {
                spinnerHide();
                alert('Неудачно!');
            });
        };

        function isDataObject(unknown) {
            if (typeof unknown === 'object' && unknown !== null) {
                return true;
            }
            return false;
        }

        function isDataArray(unknown) {
            var bool = Object.prototype.toString.call(unknown) === '[object Array]';
            return bool;
        }

        function getClassName(dataTd) {
            // console.log(dataTd);
            if ('isNew' in dataTd) {
                if (!!dataTd.isNew) {
                    return 'isNew';
                }
            }
            if ('isValid' in dataTd) {
                if (!!!dataTd.isValid) {
                    return 'isNotValid';
                }
            }
            return '';
        }

        function getDataReadyTable(dataReadyStr) {
            var arr = [];
            var rows = dataReadyStr.split(ENTER);
            // console.log(rows);
            for (var index = 0; index < rows.length; index++) {
                var row = rows[index];
                if (!!row) {
                    var cols = row.split(TAB);
                    arr.push(cols);
                }
            }
            return arr;
        };
    }
]);
