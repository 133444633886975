'use strict';

angular.module('lmsApp').controller('SchoolController', function ($scope, School, Locations, SchoolNew) {
    $scope.schools = [];
    $scope.territories = [];
    $scope.sorting = {
        sorting_fields: 'id',
        search_text: ''
    }
    //location.name sorting
    // search_text
    $scope.terOptions = {
        'page': 1,
        'per_page': 10,
        'sorting_fields': ['id'],
        'sorting_direction': 'asc'
    }
    $scope.pagination = {
        page: 1,
        total: 0,
        per_page: 20,
        sorting_fields: ['id'],
    };
    $scope.learningclasss = [];
    $scope.errors = '';

    $scope.loadAll = function (sorting) {
        var sort = sorting ? sorting : $scope.pagination
        SchoolNew.getAll(sort, function (result, headers) {
            $scope.schools = result;
            $scope.pagination.total = headers('X-Total-Count');
        });
        $scope.getTerritories();
        // $scope.learningclasss = Learningclass.query();
    };

    $scope.changePerPageCount = function (number) {
        $scope.pagination.per_page = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.create = function () {
        var newSchool = {};
        newSchool.locationId = $scope.school.location.id;
        newSchool.name = $scope.school.name;
        newSchool.vkGroupId = $scope.school.vkGroupId > 0 ? $scope.school.vkGroupId : null;
        newSchool.partnerProgram = $scope.school.partnerProgram;
        newSchool.branch = $scope.school.branch;
        newSchool.vendorID = $scope.school.vendorID || null;
        newSchool.inn = $scope.school.inn || null;
        // ------
        if($scope.school.id !== null && $scope.school.id !== undefined){
            newSchool.id = $scope.school.id;
            SchoolNew.update(newSchool,
                function () {
                    $scope.refresh();
                });            
        } else {

        SchoolNew.create(newSchool,
            function () {
                $scope.refresh();
            });
        }
    };

    $scope.update = function (id) {
        SchoolNew.getById({
            id: id
        }, function (result) {
            result.vkGroupId = +result.vkGroupId;
            $scope.school = result;
            $('#saveSchoolModal').modal('show');
        });
    };

    $scope.delete = function (id) {
        SchoolNew.getById({
            id: id
        }, function (result) {
            $scope.school = result;
            $('#deleteSchoolConfirmation').modal('show');
        });
    };

    $scope.confirmDelete = function (id) {
        School.delete({
                id: id
            },
            function () {
                $scope.refresh();
                $('#deleteSchoolConfirmation').modal('hide');
            },
            function (response) {
                $scope.errors = response.headers('Failure');
            }
        );
    };

    $scope.refresh = function () {
        $scope.loadAll();
        $('#saveSchoolModal').modal('hide');
        $scope.clear();
    };

    $scope.clear = function () {
        $scope.errors = '';
        $scope.school = {
            name: null,
            id: null
        };
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };

    $scope.getTerritories = function (loadOptions) {
        loadOptions = (loadOptions) ? loadOptions : $scope.terOptions;
        loadOptions['type'] = 'TERRITORY';
        Locations.query(loadOptions, function (result, headers) {
            $scope.territories = {
                'items': result,
                'total': headers('X-Total-Count'),
            };
        });
    };
    
    $scope.sort = function (fields) {


        //Меняем порядок сортировки, если выбран тот же столбец
        if (fields === $scope.sorting.sorting_fields) {
            if ($scope.sorting.sorting_direction === 'asc') {
                $scope.sorting.sorting_direction = 'desc';
            } else {
                $scope.sorting.sorting_direction = 'asc';
            }
        } else {
            //Меняем столбец сортировки
            $scope.sorting.sorting_fields = fields;
            $scope.sorting.sorting_direction = 'asc';
        }

        $scope.pagination.page = 1;
        $scope.loadAll($scope.sorting);
    };
    $scope.loadAll();
});