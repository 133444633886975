"use strict";

angular.module("lmsApp").controller("CourseSectionController", ['$scope', '$timeout', 'CourseSection', 'Learningclass', 'Users', 'Course', 'CourseGroup', 'UserGroup', 'Locations', 'Territory', 'School', 'SchoolNew', '$rootScope', function($scope, $timeout, CourseSection, Learningclass, Users, Course, CourseGroup, UserGroup, Locations, Territory, School, SchoolNew, $rootScope) {
    var initializing = true;

    $scope.courseSections = [];
    $scope.courses = [];
    $scope.courseGroups = [];
    $scope.addableCourses = [];
    $scope.users = [];
    $scope.userGroups = [];
    $scope.regions = [];
    $scope.territories = [];
    $scope.schools = [];
    $scope.teachers = [];
    $scope.edit = false;
    $scope.selectedRegion = null;
    $scope.selectedTerritory = null;
    $scope.selectedSchool = null;
    $scope.selectedTeacher = null;
    $scope.selectedCourseGroup = null;
    $scope.selectedCourse = null;
    $scope.csNameUserEdit = false;
    $scope.currentCourseGroup = null;
    $scope.flag = false;
    $scope.sorting = {
        fields: ["id"],
        direction: "asc"
    };
    $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
    };
    $scope.teachersOptions = {
        page: 1,
        per_page: 5,
        sorting_fields: ["lastName"],
        sorting_direction: "asc"
    };
    $scope.ugOptions = {
        page: 1,
        per_page: 5,
        sorting_fields: ["id"],
        sorting_direction: "asc"
    };
    $scope.courseOptions = {
        page: 1,
        per_page: 5,
        sorting_fields: ["id"],
        sorting_direction: "asc"
    };
    $scope.addCourseOptions = {
        page: 1,
        per_page: 5,
        sorting_fields: ["id"],
        sorting_direction: "asc"
    };

    Learningclass.getYears().$promise.then(function(response) {
        $scope.years = response;
        $scope.selectedYears = response.filter(function(year) {
          return year.value >= getСurrentYear();
        }).map(function(year) {
          return year.value;
        });
        return $scope.years.filter(function(year) {
           return year.value === getСurrentYear();
        });
      }).then(function(selectedYear) {
        // Выбрать по дефолту текущий учебный год и загрузить первичную информацию на страницу
        $scope.selectedYear = selectedYear[0];
        $scope.init();
      });

      // Показать по дефолту текущий учебный год
      function getСurrentYear() {
        var date = new Date(),
          month = date.getMonth(),
          year = date.getFullYear();
        if (month >= 5) {
          //текущий учебный год - начиная с июня, так как вряд ли в июне будут создаваться учебные классы за подходящий к концу год
          return $scope.currentYear = year;
        } else {
          return $scope.currentYear = year - 1;
        }
      }

    $scope.init = function() {
        Locations.query({
            type: "REGION"
        }, function(regions) {
            $scope.regions = regions;
            loadPrefs();
            if ($scope.selectedRegion) {
                $scope.selectedRegion = $scope.regions.filter(function(e) {
                    return e.id === $scope.selectedRegion.id;
                })[0];
                Locations.children({
                    id: $scope.selectedRegion.id
                }, function(territories) {
                    $scope.territories = territories;
                    if ($scope.selectedTerritory) {
                        $scope.selectedTerritory = $scope.territories.filter(function(e) {
                            return e.id === $scope.selectedTerritory.id;
                        })[0];
                        SchoolNew.getByTerritory({
                            id: $scope.selectedTerritory.id
                        }, function(schools) {
                            $scope.schools = schools;
                            if ($scope.selectedSchool) {
                                $scope.selectedSchool = $scope.schools.filter(function(e) {
                                    return e.id === $scope.selectedSchool.id;
                                })[0];
                                School.getById({
                                    id: $scope.selectedSchool.id
                                }, function(result) {
                                    $scope.teachers = result.teachers;
                                    if ($scope.selectedTeacher) {
                                        $scope.selectedTeacher = $scope.teachers.filter(function(e) {
                                            return e.id === $scope.selectedTeacher.id;
                                        })[0];
                                    }
                                });
                            }
                        });
                    }
                });
            }
            CourseGroup.getAll({}, function(result) {
                $scope.courseGroups = result;
                if ($scope.selectedCourseGroup) {
                    $scope.selectedCourseGroup = $scope.courseGroups.filter(function(e) {
                        return e.id === $scope.selectedCourseGroup.id;
                    })[0];
                    Course.getAllByCourseGroupId({
                        id: $scope.selectedCourseGroup.id
                    }, function(courses) {
                        $scope.courses = courses;
                        if ($scope.selectedCourse) {
                            $scope.selectedCourse = $scope.courses.filter(function(e) {
                                return e.id === $scope.selectedCourse.id;
                            })[0];
                        }
                    });
                }
            });

            $scope.loadAll();
        });
    };

    $scope.loadAll = function() {
        CourseSection.query({
            page: $scope.pagination.page,
            studyYear: $scope.selectedYear.value,
            per_page: $scope.pagination.perPage,
            sorting_fields: $scope.sorting.fields,
            sorting_direction: $scope.sorting.direction,
            regionId: $scope.selectedRegion
                ? $scope.selectedRegion.id
                : null,
            terrId: $scope.selectedTerritory
                ? $scope.selectedTerritory.id
                : null,
            schoolId: $scope.selectedSchool
                ? $scope.selectedSchool.id
                : null,
            teacherId: $scope.selectedTeacher
                ? $scope.selectedTeacher.id
                : null,
            courseGroupId: $scope.selectedCourseGroup
                ? $scope.selectedCourseGroup.id
                : null,
            courseId: $scope.selectedCourse
                ? $scope.selectedCourse.id
                : null
        }, function(result, headers) {
            $scope.pagination.total = headers("X-Total-Count");
            savePrefs();
            $scope.courseSections = result;
        });
    };

    $scope.changeActiveStatus = function(status, id) {
        CourseSection.changeActiveStatus({
            id: id
        }, function() {
            var arr = $scope.courseSections.filter(function(item) {
                return item.id == id;
            });
            if (arr.length == 1) {
                arr[0].active = status;
            }
        });
    };

    $scope.onRegionChange = function() {
        if ($scope.selectedRegion) {
            $scope.territories = Locations.children({
                id: $scope.selectedRegion.id
            });
        }
        $scope.selectedTerritory = null;
        $scope.selectedSchool = null;
        $scope.selectedTeacher = null;
        $scope.onTeacherChange();
    };

    $scope.onTerritoryChange = function() {
        if ($scope.selectedTerritory) {
            $scope.schools = SchoolNew.getByTerritory({
                id: $scope.selectedTerritory.id
            });
        }
        $scope.selectedSchool = null;
        $scope.selectedTeacher = null;
        $scope.onTeacherChange();
    };

    $scope.onSchoolChange = function() {
        if ($scope.selectedSchool) {
            School.getById({
                id: $scope.selectedSchool.id
            }, function(result) {
                $scope.teachers = result.teachers;
            });
        }
        $scope.selectedTeacher = null;
        $scope.onTeacherChange();
    };

    $scope.onTeacherChange = function() {
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.onCourseGroupChange = function() {
        if ($scope.selectedCourseGroup) {
            $scope.courses = Course.getAllByCourseGroupId({
                id: $scope.selectedCourseGroup.id
            });
        }
        $scope.selectedCourse = null;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.onCourseChange = function() {
        $scope.pagination.page = 1;
        $scope.loadAll();
    };
    $scope.onYearChange = function() {
        $scope.loadAll()
    }
    $scope.changePerPageCount = function(number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.sort = function(fields) {
        //Меняем порядок сортировки, если выбран тот же столбец
        if (fields[0] === $scope.sorting.fields[0]) {
            if ($scope.sorting.direction === "asc") {
                $scope.sorting.direction = "desc";
            } else {
                $scope.sorting.direction = "asc";
            }
        } else {
            //Меняем столбец сортировки
            $scope.sorting.fields = fields;
            $scope.sorting.direction = "asc";
        }
        $scope.pagination.page = 1;
        $scope.loadAll();
    };
    $scope.showCreate = function() {
        $scope.clear();
        $scope.getCourses();
        $scope.getUserGroups();
        $scope.edit = false;
        $scope.csNameUserEdit = false;
        $rootScope.$broadcast("clearLongList");
    };
    $scope.showUpdate = function(id) {
        $scope.clear();
        CourseSection.getWithSchool({
            id: id
        }, function(result) {
            $scope.courseSection = result.courseSection;
            if ($scope.courseSection.userGroup !== void 0 && $scope.courseSection.userGroup !== null) {
                $scope.courseSection.userGroup.schoolName = result.school
                    ? result.school.name
                    : null;
            }
            $scope.currentCourseGroup = $scope.courseSection.userGroup;
            $scope.currentCourse = $scope.courseSection.course;
            $scope.getCourses();
            $scope.getUserGroups();
            $scope.edit = true;
            $scope.csNameUserEdit = true;
            $rootScope.$broadcast("clearLongList");
            $("#saveCourseSectionModal").modal("show");
        });
    };
    $scope.save = function() {
        $scope.flag = true;
        if ($scope.courseSection.user !== void 0 && $scope.courseSection.user !== null && $scope.courseSection.user.id === null) {
            $scope.courseSection.user = null;
        }
        if ($scope.courseSection.userGroup !== void 0 && $scope.courseSection.userGroup !== null && $scope.courseSection.userGroup.id === null) {
            $scope.courseSection.userGroup = null;
        }
        CourseSection.update($scope.courseSection, function() {
            $scope.refresh();
        }, function(response) {
            $scope.userError = response.headers("Failure");
            $scope.flag = false;
        });
    };
    $scope.delete = function(id) {
        CourseSection.get({
            id: id
        }, function(result) {
            $scope.courseSection = result;
            $("#deleteCourseSectionConfirmation").modal("show");
        });
    };
    $scope.confirmDelete = function(id) {
        CourseSection.delete({
            id: id
        }, function() {
            $scope.loadAll();
            $("#deleteCourseSectionConfirmation").modal("hide");
            $scope.clear();
        });
    };
    $scope.refresh = function() {
        $scope.loadAll();
        $("#saveCourseSectionModal").modal("hide");
        $scope.clear();
    };
    $scope.clear = function() {
        $scope.userError = "";
        $scope.currentCourse = null;
        $scope.currentCourseGroup = null;
        $scope.currentTeacher = null;
        $scope.flag = false;
        $scope.courseSection = {
            name: null,
            id: null,
            course: null,
            user: null,
            userGroup: null
        };
        $scope.teachersList = {};
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };
    $scope.getCourses = function(loadOptions) {
        loadOptions = loadOptions
            ? loadOptions
            : $scope.courseOptions;
        Course.query(loadOptions, function(result, headers) {
            $scope.coursesPag = {
                items: result,
                total: headers("X-Total-Count")
            };
        });
    };
    $scope.getAddableCourses = function(loadOptions, onInit) {
        loadOptions = loadOptions
            ? loadOptions
            : $scope.addCourseOptions;
        Course.getPublished(loadOptions, function(result, headers) {
            $scope.addableCourses = result;
            $scope.addCoursesPag = {
                items: $scope.addableCourses,
                total: headers("X-Total-Count")
            };
        });
    };
    $scope.getUserGroups = function(loadOptions) {
        loadOptions = loadOptions
            ? loadOptions
            : $scope.ugOptions;
        UserGroup.getAllRich(loadOptions, function(result, headers) {
            $scope.userGroups = {
                items: result,
                total: headers("X-Total-Count")
            };
        });
    };
    $scope.getTeachers = function(loadOptions) {
        loadOptions = loadOptions
            ? loadOptions
            : $scope.teachersOptions;
        if ($scope.courseSection && $scope.courseSection.course && $scope.courseSection.course.id) {
            loadOptions.courseId = $scope.courseSection.course.id;
            Users.getTeachersByCourse(loadOptions, function(result, headers) {
                $scope.teachersList = {
                    items: result,
                    total: headers("X-Total-Count")
                };
            });
        }
    };
   
    function loadPrefs() {
        var stSort = JSON.parse(localStorage.getItem("courseSectionSort")),
            stPagination = JSON.parse(localStorage.getItem("courseSectionPagination")),
            stIds = JSON.parse(sessionStorage.getItem("courseSectionIds")),
            stPage = sessionStorage.getItem("courseSectionPage");
        if (stSort) {
            $scope.sorting = stSort;
        }
        if (stPagination) {
            $scope.pagination = stPagination;
            $scope.pagination.page = 1;
        }
        if (stIds) {
            $scope.selectedRegion = stIds[0];
            $scope.selectedTerritory = stIds[1];
            $scope.selectedSchool = stIds[2];
            $scope.selectedTeacher = stIds[3];
            $scope.selectedCourseGroup = stIds[4];
            $scope.selectedCourse = stIds[5];
        }
        if (stPage) {
            $scope.pagination.page = stPage;
        }
    }
    function savePrefs() {
        var ids = [
            $scope.selectedRegion,
            $scope.selectedTerritory,
            $scope.selectedSchool,
            $scope.selectedTeacher,
            $scope.selectedCourseGroup,
            $scope.selectedCourse
        ];
        localStorage.setItem("courseSectionSort", JSON.stringify($scope.sorting));
        localStorage.setItem("courseSectionPagination", JSON.stringify($scope.pagination));
        sessionStorage.setItem("courseSectionIds", JSON.stringify(ids));
        sessionStorage.setItem("courseSectionPage", $scope.pagination.page);
    }
    $scope.csNameChanged = function() {
        $scope.csNameUserEdit = true;
    };
    $scope.$watch("courseSection", function(v) {
        if (v && !$scope.edit && !$scope.csNameUserEdit) {
            v.name = "";
            v.name += $scope.courseSection.course
                ? $scope.courseSection.course.name + " "
                : "";
            v.name += $scope.courseSection.userGroup
                ? $scope.courseSection.userGroup.name
                : "";
        }
    }, true);
    $scope.$watch("courseSection.course", function(v) {
        if (v && v.id) {
            $scope.getTeachers();
        }
    });
}]);
