'use strict';

angular
    .module('lmsApp')
    .controller(
        'CourseOpenTaskController',
        function($scope, $rootScope, $state, $stateParams, $sce, Course, AssignmentProgress, Principal) {
            var _statusList = {
                NOT_STARTED: 'Не начато',
                SAVED: 'Сохранено',
                PUBLISHED: 'Опубликовано',
                ASSESSED: 'Отметка'
            };

            $scope.openTask = {};
            $scope.htmlText = '';
            $scope.taskStatus = '';

            $scope.canGiveMarks = Principal.isInRole('OP_VIEW_COURSE_SECTIONS');

            $scope.assignmentProgress = {};
            $scope.noProgress = false;

            $scope.load = function(id, imsccId) {
                $scope.courseId = id;

                Course.get({ id: id }, function(result) {
                    $scope.course = result;
                });

                Course.getOpenTask(
                    { id: id, taskImsccId: imsccId },
                    function(openTask) {
                        $scope.openTask = openTask;
                        $scope.htmlText = $sce.trustAsHtml(openTask.text);
                    }
                );

                AssignmentProgress.getStatus(
                    {
                        courseId: $stateParams.id,
                        imsccId: $stateParams.imsccId,
                        userId: Principal.isInRole('ROLE_STUDENT')
                            ? null
                            : $stateParams.studentId
                    },
                    function(data) {
                        $scope.taskStatus = _statusList[data.status];
                    },
                    function(response) {
                        if (response.status === 404) {
                            $scope.noProgress = true;
                        }
                    }
                );

                AssignmentProgress.getByAssignmentIdAndStudentId(
                    {
                        courseId: $stateParams.id,
                        imsccId: $stateParams.imsccId,
                        userId: Principal.isInRole('ROLE_STUDENT')
                            ? null
                            : $stateParams.studentId
                    },
                    function(data) {
                        $scope.assignmentProgress = data;
                    }
                );
            };
            $scope.load($stateParams.id, $stateParams.imsccId);

            $scope.setMark = function() {
                AssignmentProgress.setMark(
                    {
                        courseId: $stateParams.id,
                        imsccId: $stateParams.imsccId,
                        userId: $stateParams.studentId
                    },
                    { mark: $scope.assignmentProgress.mark },
                    function(data, getHeaders) {
                        alert('Отметка поставлена.');
                    }
                );
            };

            /**
         * Performs update of the mark status.
         */
            $scope.publishMark = function() {
                AssignmentProgress.publishMark(
                    { id: $scope.assignmentProgress.id },
                    { published: $scope.assignmentProgress.markPublished },
                    function() {
                        alert('Изменения внесены.');
                    }
                );
            };

            $scope.goBack = function() {
                if ($rootScope.previousStateName.length === 0) {
                    // go to the open task list
                    $state.go('courseOpenTasks', $stateParams);
                } else {
                    $state.go(
                        $rootScope.previousStateName,
                        $rootScope.previousStateParams
                    );
                }
            };
        }
    );
