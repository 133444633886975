'use strict';

angular.module('lmsApp').factory('Learningclass', function($resource) {
  return $resource(
    'api/learningClasses/:id',
    {},
    {
      query: { method: 'GET', isArray: true },
      get: {
        method: 'GET',
        transformResponse: function(data) {
          data = angular.fromJson(data);
          return data;
        }
      },
      getBySchool: {
        method: 'GET',
        url: 'api/learningClasses/schools/:id',
        isArray: true
      },
      getAllClasses: {
        method: 'GET',
        url: 'api/learningClasses',
        isArray: true
      },
      getYears: {
        method: 'GET',
        url: 'api/learningYears',
        isArray: true
      },
      create: { method: 'POST' },
      update: { method: 'PUT' },
      getPossibleLearningClass: {
        method: 'GET',
        url: 'api/learningClasses/free?',
        isArray: true
      }
    }
  );
});
