'use strict';

angular.module('lmsApp')
    .controller('ConversationDetailController', function ($scope, $stateParams, Conversation, Conversations, Groups, Users,
                                                          Principal) {

        $scope.conversation = {};
        $scope.userGroups = null;
        $scope.users = null;
        $scope.newParticipant
        $scope.newParticipantGrp
        $scope.usersOptions = {
            'page': 1,
            'per_page': 10,
            'sorting_fields': ['id'],
            'sorting_direction': 'asc'
        };
        $scope.ugOptions = {
            'page': 1,
            'per_page': 10,
            'sorting_fields': ['id'],
            'sorting_direction': 'asc'
        };

        $scope.load = function (id) {
            if(!id && $stateParams.id) {
                id = $stateParams.id;
            }

            Conversation.get({id: id}, function (data) {
                $scope.conversation = data;
                $scope.getAvailableUsers();
                $scope.getUserGroups();
            });
        };



        $scope.showAddParticipantDialog = function () {
            $('#addParticipantModal').modal('show');
        };

        $scope.showAddParticipantGrpDialog = function () {
            $('#addParticipantGrpModal').modal('show');
        };

        $scope.showDeleteParticipantDialog = function () {
            $('#deleteParticipantModal').modal('show');
        };

        $scope.showDeleteAllParticipantsDialog = function () {
            $('#deleteAllParticipantsModal').modal('show');
        };

        $scope.addParticipant = function () {

            $scope.conversation.participants.push($scope.newParticipant);
            var participant = {
                firstId: $scope.conversation.id,
                secondId: $scope.newParticipant.id
            };

            Conversations.addParticipant(participant,
                function (data) {
                    $scope.conversation = data;
                    $('#addParticipantModal').modal('hide');
                    $scope.newParticipant = void 0;
                    $scope.load($stateParams.id);
                    $scope.clear();
                }
            );
        };

        $scope.addParticipantGrp = function () {
            var group = {
                firstId: $scope.conversation.id, //
                secondId: $scope.newParticipantGrp.id // groupid
            };
            Conversations.addGroup(group,
                function (data) {
                    $scope.conversation = data;
                    $('#addParticipantGrpModal').modal('hide');
                    $scope.load($stateParams.id);
                    $scope.clear();
                }
            );
        };

        $scope.deleteParticipant = function () {

            // removeParticipantById($scope.removedParticipantId);
            var participant = {
                id: $scope.conversation.id,
                user_id: +$scope.removedParticipantId
            }
            Conversations.deleteParticipant(participant,
                function (data) {
                    $scope.conversation = data;
                    $('#deleteParticipantModal').modal('hide');
                    $scope.load($stateParams.id);
                    $scope.clear();
                }
            );
        };

        /**
         * Removes all conversation's participants except for conversation owner.
         */
        $scope.deleteAllParticipants = function () {
            Conversations.deleteAllParticipant({
                conversationId: $scope.conversation.id
            }, function() {
                $('#deleteAllParticipantsModal').modal('hide');
                $scope.load($stateParams.id);
                $scope.clear();
            });
        };

        $scope.clear = function () {
            $scope.newParticipantId = null;
            $scope.addParticipantForm.$setPristine();
            $scope.addParticipantForm.$setUntouched();

            $scope.newParticipantGrp = null;
            $scope.addParticipantGrpForm.$setPristine();
            $scope.addParticipantGrpForm.$setUntouched();

            $scope.removedParticipantId = '';
            $scope.deleteParticipantForm.$setPristine();
            $scope.deleteParticipantForm.$setUntouched();
        };


        $scope.getAvailableUsers = function(loadOptions) {
            loadOptions = (loadOptions) ? loadOptions : $scope.usersOptions;
            loadOptions.id = $scope.conversation.id;
/*            Users.getAllUsersForConversation(loadOptions, function (result, headers) {
                $scope.users = {
                    'items' : result,
                    'total' : headers('X-Total-Count'),
                };
            });*/
        };

        $scope.getUserGroups = function(loadOptions) {
            loadOptions = (loadOptions) ? loadOptions : $scope.ugOptions;
            loadOptions.id = $scope.conversation.id;
            Groups.getAllFreeGroups(loadOptions, function (result, headers) {
                $scope.userGroups = {
                    'items' : result,
                    'total' : headers('X-Total-Count'),
                };
            });
        };

        $scope.load($stateParams.id);
    });
