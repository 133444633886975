'use strict';

angular.module('lmsApp').controller('CourseGroupController', function ($scope, CourseGroup, Course) {
    $scope.courseGroups = [];
    $scope.coursesInCourseGroup = 0;
    $scope.courses = Course.query();
    $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
    };

    $scope.loadAll = function () {
        CourseGroup.query({
            page: $scope.pagination.page,
            per_page: $scope.pagination.perPage
        }, function (result, headers) {
            $scope.pagination.total = headers('X-Total-Count');
            $scope.courseGroups = result;
        });
    };

    $scope.showUpdate = function (id) {
        CourseGroup.get({
            id: id
        }, function (result) {
            $scope.courseGroup = result;
            $('#saveCourseGroupModal').modal('show');
        });
    };

    $scope.save = function () {
        if ($scope.courseGroup.id !== void 0 && $scope.courseGroup.id !== null) {
            CourseGroup.update($scope.courseGroup,
                function () {
                    $scope.refresh();
                });
        } else {
            CourseGroup.save($scope.courseGroup,
                function () {
                    $scope.refresh();
                });
        }
    };

    $scope.delete = function (id) {
        Course.getAllByCourseGroupId({id: id}, function (result) {
            $scope.courses = result;
            if ($scope.courses.length > 0) {
                $scope.coursesInCourseGroup = 1
            }
        });
        CourseGroup.get({
            id: id
        }, function (result) {
            $scope.courseGroup = result;
            $('#deleteCourseGroupConfirmation').modal('show');
        });
    };

    $scope.confirmDelete = function (id) {
        CourseGroup.delete({
                id: id
            },
            function () {
                $scope.loadAll();
                $('#deleteCourseGroupConfirmation').modal('hide');
                $scope.clear();
            });
    };

    $scope.refresh = function () {
        $scope.loadAll();
        $('#saveCourseGroupModal').modal('hide');
        $scope.clear();
    };

    $scope.clear = function () {
        $scope.courseGroup = {
            name: null,
            id: null
        };
        $scope.coursesInCourseGroup = 0;
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };

    $scope.changePerPageCount = function (number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.loadAll();
});
