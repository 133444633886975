'use strict';

angular.module('lmsApp').factory('ImportUsers', function ($resource) {
    return $resource('admin/import-users', {}, {
        'post': {
            method: 'POST',
            url: 'admin/import-users',
            isArray: true
        },
        'checkData': {
            method: 'POST',
            url: 'admin/import-users/check',
            isObject: true
        }
    });
});
