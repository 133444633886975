'use strict';

angular
    .module('lmsApp', [
        'LocalStorageModule',
        'tmh.dynamicLocale',
        'ngResource',
        'ui.router',
        'toastr',
        'ngCookies',
        'pascalprecht.translate',
        'ngCacheBuster',
        'infinite-scroll',
        'ngFileUpload',
        'ngSanitize',
        'ngGrid',
        'puElasticInput',
        'ui.select',
        'treeControl',
        'ipCookie',
        'ui.bootstrap',
        'uuid',
        'dialogs.main',
        'angularSpinner'
    ])
    .run(
        function($rootScope, $location, $window, $http, $state, $translate, Auth, Principal, Language, ENV, VERSION, GIT) {
            $rootScope.ENV = ENV;
            $rootScope.VERSION = VERSION;
            $rootScope.GIT = GIT;
            $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams) {
                if (toState.name === 'login') {
                    event.preventDefault();
                    Auth.goLoginPage();
                    return;
                }

                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

                // Update the language
                Language.getCurrent().then(function(language) {
                    $translate.use(language);
                });
            });

            $rootScope.$on(
                '$stateChangeSuccess',
                function(event, toState, toParams, fromState, fromParams) {
                    var titleKey = 'global.title';

                    $rootScope.previousStateName = fromState.name;
                    $rootScope.previousStateParams = fromParams;

                    // Set the page title key to the one configured in state or use default one
                    if (toState.data.pageTitle) {
                        titleKey = toState.data.pageTitle;
                    }
                    $translate(titleKey).then(function(title) {
                        // Change window title with translated one
                        $window.document.title = title;
                    });
                }
            );

            $rootScope.back = function() {
                // If previous state is 'activate' or do not exist go to 'home'
                if (
                    $rootScope.previousStateName === 'activate' ||
                    $state.get($rootScope.previousStateName) === null
                ) {
                    $state.go('home');
                } else {
                    $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
                }
            };

            if ($rootScope.ENV === 'prod') {
                tinyMCE.baseURL = window.location.origin + '/app/components/util/tinymce';
            }
        }
    )
    .factory(
        'authInterceptor',
        function($rootScope, $q, $location, $injector, localStorageService) {
            return {
                // Add authorization token to headers
                request: function(config) {
                    config.headers = config.headers || {};
                    var token = localStorageService.get('token');

                    if (token) {
                        var tokenParts = token.token.split(':');
                        if (
                            (token.expires && token.expires > Date.now()) ||
                            (tokenParts[3] && tokenParts[3] === 'true')
                        ) {
                            config.headers['x-auth-token'] = token.token;
                        }
                    }

                    return config;
                },

                // Reauthorize and recover request
                responseError: function(resp) {
                    var response = $injector.invoke([
                        '$http',
                        '$state',
                        'ipCookie',
                        function($http, $state, ipCookie) {
                            if (resp.status === 401 && resp.config.url.match('api/')) {
                                var headers = resp.headers();
                                if (
                                    headers['x-auth-recreate'] &&
                                    headers['x-auth-recreate'] === 'true'
                                ) {
                                    // save new token and recover the request
                                    localStorageService.set('token', resp.data);
                                    // set cookie, so a user can access static resources too
                                    ipCookie('LMS_AUTH', resp.data.token, {
                                        path: '/',
                                        expires: 7
                                    });
                                    return $http(resp.config);
                                }

                                localStorage.setItem('adminPrevLocation', window.location.href);
                                $state.go('login');
                                return null;
                            }
                        }
                    ]);

                    if (response) {
                        return response;
                    }

                    return $q.reject(resp);
                }
            };
        }
    )
    .config(
        function($stateProvider, $urlRouterProvider, $resourceProvider, $httpProvider, $locationProvider, $translateProvider, tmhDynamicLocaleProvider, httpRequestInterceptorCacheBusterProvider, AuthProvider) {
            $resourceProvider.defaults.stripTrailingSlashes = false;
            if (localStorage.getItem('ls.token')) {
                $httpProvider.defaults.headers.common['LMS_AUTH'] = JSON.parse(
                    localStorage.getItem('ls.token')
                ).token;
            }
            //Enable cross domain calls
            $httpProvider.defaults.withCredentials = true;
            $httpProvider.defaults.useXDomain = true;

            //Remove the header used to identify ajax call  that would prevent CORS from working
            // delete $httpProvider.defaults.headers.common['X-Requested-With'];

            if (!window.location.origin) {
                window.location.origin = window.location.protocol +
                    '//' +
                    window.location.hostname +
                    (window.location.port ? ':' + window.location.port : '');
            }

            AuthProvider.setAppRoles([
                'ROLE_ADMIN',
                'ROLE_LOCAL_ADMIN',
                'ROLE_SCHOOL_ADMIN',
                'ROLE_COURSE_DEVELOPER'
            ]);
            AuthProvider.setLoginUrl(window.location.origin + '/ui/index.html#/login');

            //Cache everything except rest api requests
            httpRequestInterceptorCacheBusterProvider.setMatchlist(
                [/.*api.*/, /.*protected.*/],
                true
            );

            $urlRouterProvider.otherwise('/');
            $stateProvider.state('site-without-navbar', {
                abstract: true,
                resolve: {
                    authorize: [
                        'Auth',
                        function(Auth) {
                            return Auth.authorize();
                        }
                    ],
                    translatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('language');
                            return $translate.refresh();
                        }
                    ]
                }
            });

            $stateProvider.state('site', {
                abstract: true,
                parent: 'site-without-navbar',
                views: {
                    'navbar@': {
                        templateUrl: 'app/components/navbar/navbar.html',
                        controller: 'NavbarController'
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('cruduser');
                            return $translate.refresh();
                        }
                    ]
                }
            });

            $httpProvider.interceptors.push('authInterceptor');

            // Initialize angular-translate
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: 'i18n/ru/{part}.json'
            });

            $translateProvider.preferredLanguage('ru');
            $translateProvider.useCookieStorage();

            tmhDynamicLocaleProvider.localeLocationPattern(
                'bower_components/angular-i18n/angular-locale_{{locale}}.js'
            );
            tmhDynamicLocaleProvider.useCookieStorage('NG_TRANSLATE_LANG_KEY');
        }
    );

angular.module('lmsApp').constant('adaptedProgramList', [
    {
        id: 'C_DEFAULT',
        name: 'Основная программа'
    },
    {
        id: 'C_AQUA',
        name: 'Адаптированная программа №1 (для детей с нарушениями речи)'
    },
    {
        id: 'C_BLUE',
        name: 'Адаптированная программа №2 (для детей с нарушениями речи)'
    },
    {
        id: 'C_YELLOW',
        name: 'Адаптированная программа №3 (для детей с задержкой психического развития - темповое временное отставание)'
    },
    {
        id: 'C_ORANGE',
        name: 'Адаптированная программа №4 (для детей с задержкой психического развития церебрально-органического генеза)'
    },
    {
        id: 'C_RED',
        name: 'Адаптированная программа №5 (для детей с умственной отсталостью)'
    },
    { id: 'C_GREEN', name: 'Адаптированная программа №6 (для детей с ...)' },
    { id: 'C_PURPLE', name: 'Адаптированная программа №7 (для детей с ...)' },
    { id: 'C_GREY', name: 'Адаптированная программа №8 (для детей с ...)' }
]);
