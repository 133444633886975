'use strict';

angular
    .module('lmsApp')
    .controller(
        'UserGroupController',
        function($scope, UserGroup, Users, Classes, CourseSection, Principal, Region, Territory, SchoolNew, Locations, Learningclass, $q, $timeout, usSpinnerService) {
            $scope.userGroups = [];
            $scope.users = [];
            $scope.owners = [];
            $scope.tutors = [];
            $scope.userGroupError = '';
            $scope.currentUser = {};
            $scope.regions = [];
            $scope.territories = [];
            $scope.schools = [];
            $scope.years = [];
            $scope.selectedCurrentYear = null;
            $scope.selectedNextYear = null;
            $scope.selectedSchool = null;
            $scope.searchText = '';
            $scope.learningClasses = null;
            $scope.spinnerCurtain = false;

            $scope.sorting = {
                fields: ['id'],
                direction: 'asc'
            };
            $scope.pagination = {
                page: 1,
                total: 0,
                perPage: 20
            };
            $scope.loadDefaultOptions = {
                page: 1,
                per_page: 5,
                sorting_fields: ['id'],
                sorting_direction: 'asc'
            };

            $scope.loadAll = function(withSearch) {
                if (!withSearch) {
                    $scope.searchText = '';
                }
                var prefs = {
                    page: $scope.pagination.page,
                    per_page: $scope.pagination.perPage,
                    sorting_fields: $scope.sorting.fields,
                    sorting_direction: $scope.sorting.direction,
                    search_text: $scope.searchText ? $scope.searchText : null,
                    regionId: $scope.selectedRegion ? $scope.selectedRegion.id : null,
                    terrId: $scope.selectedTerritory ? $scope.selectedTerritory.id : null,
                    schoolId: $scope.selectedSchool ? $scope.selectedSchool.id : null,
                    year: $scope.selectedYear ? $scope.selectedYear.value : null
                };
                savePrefs(prefs);
                UserGroup.getAllRich(prefs, function(result, headers) {
                    $scope.pagination.total = headers('X-Total-Count');
                    $scope.userGroups = result;
                });
            };

            init();

            $scope.onYearChange = function() {
                $scope.loadAll(true);
            };

            $scope.onRegionChange = function() {
                if ($scope.selectedRegion) {
                    $scope.territories = Locations.children({
                        id: $scope.selectedRegion.id
                    });
                }
                $scope.selectedTerritory = null;
                $scope.selectedSchool = null;
                $scope.onSchoolChange();
            };

            $scope.onTerritoryChange = function() {
                if ($scope.selectedTerritory) {
                    $scope.schools = SchoolNew.getByTerritory({
                        id: $scope.selectedTerritory.id
                    });
                }
                $scope.selectedSchool = null;
                $scope.onSchoolChange();
            };

            $scope.onSchoolChange = function() {
                $scope.pagination.page = 1;
                $scope.loadAll(true);
            };

            $scope.changePerPageCount = function(number) {
                $scope.pagination.perPage = number;
                $scope.pagination.page = 1;
                $scope.loadAll(true);
            };

            $scope.sort = function(fields) {
                //Меняем порядок сортировки, если выбран тот же столбец
                if (fields[0] === $scope.sorting.fields[0]) {
                    if ($scope.sorting.direction === 'asc') {
                        $scope.sorting.direction = 'desc';
                    } else {
                        $scope.sorting.direction = 'asc';
                    }
                } else {
                    //Меняем столбец сортировки
                    $scope.sorting.fields = fields;
                    $scope.sorting.direction = 'asc';
                }
                $scope.pagination.page = 1;
                $scope.loadAll(true);
            };
            $scope.search = function() {
                $scope.pagination.page = 1;
                $scope.loadAll(true);
            };
            $scope.getLearningClass = function(learningClassOptions) {
                learningClassOptions = learningClassOptions
                    ? learningClassOptions
                    : $scope.loadDefaultOptions;
                Classes.getFreeClasses(learningClassOptions, function(result, headers) {
                    $scope.learningClass = {
                        items: result.length > 0 ? result : [null],
                        total: headers('X-Total-Count')
                    };
                });
            };
            $scope.getTutors = function(tutorOptions) {
                tutorOptions = tutorOptions ? tutorOptions : $scope.loadDefaultOptions; // loadOptions['per_page'] = 5;
                tutorOptions.learningclass = $scope.learningClasses !== undefined
                    ? $scope.learningClasses.id
                    : $scope.learningClass.items[0].id;
                Users.getTutors(tutorOptions, function(result, headers) {
                    if (result.length < 1 && !$scope.userGroup.tutor) {
                        $scope.tutors = {};
                    } else if (result.length < 1) {
                        $scope.tutors = {
                            items: [$scope.currenUserGroup]
                        };
                    } else {
                        $scope.tutors = {
                            items: result,
                            total: headers('X-Total-Count')
                        };
                    }
                });
            };
            $scope.create = function() {
                $scope.spinnerCurtain = true;
                usSpinnerService.spin('spinnerUserGroupUpdate');
                if ($scope.userGroup && $scope.learningClasses) {
                    var usergroup = {
                        tutorId: $scope.userGroup.tutor ? $scope.userGroup.tutor.id : null,
                        learningClassId: $scope.learningClasses.id,
                        name: $scope.userGroup.name
                    };
                    if ($scope.userGroup.id !== undefined && $scope.userGroup.id !== null) {
                        usergroup.id = $scope.userGroup.id;
                        if (
                            $scope.userGroup.users !== undefined &&
                            $scope.userGroup.users.length > 0
                        ) {
                            usergroup.userIds = $scope.userGroup.users.map(function(user) {
                                return user.id;
                            });
                        }
                    }
                }
                UserGroup.update(usergroup, function() {
                    $scope.loadAll(true);
                    $('#saveUserGroupModal').modal('hide');
                    usSpinnerService.stop('spinnerUserGroupUpdate');
                    $scope.spinnerCurtain = false;
                    $scope.clear();
                });
            };
            $scope.update = function(id) {
                $scope.clear();
                UserGroup.get(
                    {
                        id: id
                    },
                    function(result) {
                        $scope.userGroup = result;
                        $scope.currenUserGroup = _.clone($scope.userGroup.tutor);
                        $scope.learningClasses = result.learningClasses[0];
                        $('#saveUserGroupModal').modal('show');
                    }
                );
            };
            $scope.delete = function(id) {
                CourseSection.getAllByUserGroupId({ id: id }, function(courseSections) {
                    if (courseSections.length > 0) {
                        var courseSectionNames = [];
                        courseSections.forEach(function(item) {
                            courseSectionNames.push(item.name);
                        });
                        $scope.courseSectionNames = courseSectionNames.join(', ');
                        $('#userGroupHaveCourseSection').modal('show');
                    } else {
                        UserGroup.get(
                            {
                                id: id
                            },
                            function(result) {
                                $scope.userGroup = result;
                                $('#deleteUserGroupConfirmation').modal('show');
                            }
                        );
                    }
                });
            };
            $scope.confirmDelete = function(id) {
                UserGroup.delete(
                    {
                        id: id
                    },
                    function() {
                        $scope.loadAll(true);
                        $('#deleteUserGroupConfirmation').modal('hide');
                        $scope.clear();
                    },
                    function(response) {
                        $scope.userGroupError = response.headers('Failure');
                    }
                );
            };
            $scope.clear = function() {
                $scope.userGroup = {
                    name: null,
                    ownerId: $scope.currentUser.id,
                    id: null
                };
                $scope.getLearningClass();
                $scope.tutors = {};
                $scope.learningClasses = null;
                $scope.userGroupError = '';
                $scope.editForm.$setPristine();
                $scope.editForm.$setUntouched();
            };
            function init() {
                Locations.query(
                    {
                        type: 'REGION'
                    },
                    function(regions) {
                        $scope.regions = regions;
                        $scope.learningClass = {
                            items: [null]
                        };
                        $scope.tutors = {
                            items: [null]
                        };
                    }
                );
                Learningclass.getYears(function(years) {
                    $scope.years = years;
                    loadPrefs().then(function() {
                        $scope.loadAll(true);
                    });
                });
            }
            function savePrefs(prefs) {
                // var prefs = {
                //   page: $scope.pagination.page,
                //   per_page: $scope.pagination.perPage,
                //   sorting_fields: $scope.sorting.fields,
                //   sorting_direction: $scope.sorting.direction,
                //   search_text: $scope.searchText ? $scope.searchText : null,
                //   regionId: $scope.selectedRegion ? $scope.selectedRegion.id : null,
                //   terrId: $scope.selectedTerritory ? $scope.selectedTerritory.id : null,
                //   schoolId: $scope.selectedSchool ? $scope.selectedSchool.id : null,
                //   year: $scope.selectedYear ? $scope.selectedYear.value : null
                // };
                sessionStorage.setItem('userGroupPage', JSON.stringify(prefs));
            }
            function loadPrefs() {
                return $q(function(resolve, reject) {
                    var userGroupPage = sessionStorage.getItem('userGroupPage');
                    if (!userGroupPage) {
                        return resolve();
                    }
                    var prefs = JSON.parse(userGroupPage);
                    $scope.selectedYear = null;
                    $scope.years.some(function(year) {
                        if (prefs.year === year.value) {
                            $scope.selectedYear = year;
                            return true;
                        }
                        return false;
                    });
                    $scope.pagination = $scope.pagination || {};
                    $scope.pagination.page = prefs.page;
                    $scope.pagination.perPage = prefs.per_page;
                    $scope.searchText = prefs.search_text;
                    $scope.sorting = $scope.sorting || {};
                    $scope.sorting.fields = prefs.sorting_fields;
                    $scope.sorting.direction = prefs.sorting_direction;
                    $scope.selectedRegion = null;
                    $scope.regions.some(function(region) {
                        if (prefs.regionId === region.id) {
                            $scope.selectedRegion = region;
                            return true;
                        }
                        return false;
                    });
                    $scope.selectedTerritory = null;
                    if ($scope.selectedRegion) {
                        Locations.children({
                            id: $scope.selectedRegion.id
                        }).$promise.then(function(res) {
                            $scope.territories = res;
                            res.some(function(territorie) {
                                if (prefs.terrId === territorie.id) {
                                    $scope.selectedTerritory = territorie;
                                    return true;
                                }
                                return false;
                            });
                            $scope.selectedSchool = null;
                            if ($scope.selectedTerritory) {
                                SchoolNew.getByTerritory({
                                    id: $scope.selectedTerritory.id
                                }).$promise.then(function(res) {
                                    $scope.schools = res;
                                    $scope.schools.some(function(school) {
                                        if (prefs.schoolId === school.id) {
                                            $scope.selectedSchool = school;
                                            return true;
                                        }
                                        return false;
                                    });
                                    return resolve();
                                });
                            } else {
                                return resolve();
                            }
                        });
                    } else {
                        return resolve();
                    }
                });
            }
        }
    );
