'use strict';

angular.module('lmsApp')
    .factory('Videoconfconfig', function ($resource) {
        return $resource('api/videoconfconfigs/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getConfByUserId': {
                method: 'GET',
                url: 'api/videoconfconfigs/conf/:id',
                isArray: true
            },
            'updateGroupUser': {
                method: 'PUT',
                url: 'api/videoconfconfigs/groupUsers',
            }
        });
    });
