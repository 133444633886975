'use strict';

angular.module('lmsApp')
    .factory('Conversation', function ($resource) {
        return $resource('api/conversations/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getByCurrentUser': {
                method: 'GET',
                url: 'api/conversations/user',
                isArray: true
            },
            'update': {method: 'PUT'},
            'create': {method: 'POST'},

            'getMessages': {
                method: 'GET',
                url: 'api/conversations/:convId/messages',
                isArray: true
            },
            'createMessage': {
                method: 'POST',
                url: 'api/conversations/:convId/messages'
            },
            'getAvailableUsers': {
                method: 'GET',
                url: 'api/conversations/availableUsers',
                isArray: true,
                params: {
                    plain: '@plain'
                }
            }
        });
    });
