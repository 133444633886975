'use strict';

angular.module('lmsApp').factory('Course', function($resource) {
    return $resource(
        'api/courses/:id',
        {},
        {
            query: { method: 'GET', isArray: true },
            get: {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            update: { method: 'PUT' },
            create: { method: 'POST' },
            getOpenTasks: {
                method: 'GET',
                url: 'api/courses/:id/openTasks',
                isArray: true
            },
            getOpenTask: {
                method: 'GET',
                url: 'api/courses/:id/openTasks/:taskImsccId'
            },
            getPublished: {
                method: 'GET',
                url: 'api/courses/published',
                isArray: true
            },
            getDynamicSyllabus: {
                method: 'GET',
                url: 'api/courses/:id/dynamicSyllabus',
                isArray: true
            },
            getQuizzes: {
                method: 'GET',
                url: 'api/courses/:id/quizzes',
                isArray: true
            },
            getRubrics: {
                method: 'GET',
                url: 'api/courses/:id/rubrics',
                isArray: true
            },
            getQuiz: {
                method: 'GET',
                url: 'api/courses/:id/quizzes/:quizImsccId'
            },
            getQuizDraft: {
                method: 'GET',
                url: 'api/courses/:id/quizzes/:quizImsccId/student/:studentId/draft'
            },
            saveQuizDraft: {
                method: 'PUT',
                url: 'api/courses/:id/quizzes/:quizImsccId/student/:studentId/draft'
            },
            answerQuiz: {
                method: 'POST',
                url: 'api/courses/:id/quizzes/:quizImsccId/student/:studentId'
            },
            isQuizAvailable: {
                method: 'GET',
                url: 'api/courses/:id/quizzes/:quizImsccId/student/:studentId/available'
            },
            getImsccPackage: {
                method: 'GET',
                url: 'api/courses/:id/imsccPackages',
                isArray: true
            },
            getAllByCourseGroupId: {
                method: 'GET',
                url: 'api/courses/courseGroup/:id',
                isArray: true
            },
            export: {
                method: 'GET',
                url: 'api/courses/:id/export'
            },
            checkUpload: {
                method: 'GET',
                url: 'api/courses/:id/upload/check'
            },
            openTasksWithParentId: {
                method: 'GET',
                url: 'api/courses/openTasksWithParentId/:imsccId',
                isArray: true
            },
            regenerateProgress: {
                method: 'POST',
                url: 'api/courses/validateCoursesAssignmentProgresses/:ids'
            }
        }
    );
});
